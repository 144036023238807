import { Button } from 'antd';

const CustomButton = ({ style, children, ...rest }) => {
  return (
    <Button
      {...rest}
      style={{
        height: 50,
        borderRadius: 4,
        margin: 'auto',
        fontSize: 15,
        textAlign: 'center',
        fontWeight: 500,
        width: '100%',
        border: 'none',
        ...style,
      }}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
