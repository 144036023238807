import axios from 'axios';

const klip = axios.create({
  baseURL: 'https://a2a-api.klipwallet.com/v2/a2a',
});

export const requestKlip = async () => {
  const response = await klip({
    method: 'POST',
    url: '/prepare',
    data: { bapp: { name: 'Hopae Connect' }, type: 'auth' },
    type: 'auth',
  });
  return response.data;
};

export const verifyKlipResponse = async (request_key) => {
  const response = await klip({
    method: 'GET',
    url: `/result?request_key=${request_key}`,
  });
  return response.data;
};
