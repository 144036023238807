import React from 'react';

import { Layout, Typography } from 'antd';
const { Text } = Typography;

const Footer = () => {
  return (
    <Layout.Footer
      style={{
        textAlign: 'center',
        borderTop: '1px solid #00000008',
        color: '#E5E5E5',
        background: 'transparent',
        position: 'absolute',
        bottom: 0,
        width: '100%',
      }}
    >
      {/* ©2021 Team Hopae Inc. All Rights Reserved */}
      <Text style={{ color: '#E5E5E5' }}>
        Powered by{' '}
        <a
          style={{ color: '#B443F7' }}
          href='https://furo.one'
          target={'_blank'}
          rel='noreferrer'
        >
          Hopae
        </a>{' '}
        with 💕
      </Text>
    </Layout.Footer>
  );
};

export default Footer;
