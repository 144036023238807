import React from 'react';

import { Layout } from 'antd';

import Footer from './Footer';
import Header from './Header';

const { Content } = Layout;

const LayoutWithRoute = ({ children }) => {
  return (
    <Layout>
      <Layout className='site-layout' style={{ background: '#1F2127' }}>
        <Header />
        <Content
          style={{
            padding: '40px 0px',
            maxWidth: 960,
            margin: 'auto',
            flex: 1,
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default LayoutWithRoute;
