import React from 'react';
import { Layout, Menu, Dropdown, Avatar, message } from 'antd';
import { DownOutlined, UserOutlined, ImportOutlined } from '@ant-design/icons';

const Logo = () => {
  return <></>;
};

const Header = () => {
  const user = undefined;
  const menu = (
    <Menu>
      <Menu.Item key='1' icon={<UserOutlined />}>
        address
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='2' icon={<ImportOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Header style={{ padding: 0, background: '#1F2127' }}>
      <div
        style={{
          padding: '0px 20px',
          maxWidth: 960,
          margin: 'auto',
        }}
      >
        <div
          style={{
            float: 'left',
            width: '240px',
            height: '24px',
            margin: '18px 24px 18px 0px',
            backgroundImage: `url('/hopae_text.svg')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        {/* <Menu
          theme='dark'
          mode='horizontal'
          selectable={false}
          style={{ background: 'transparent' }}
        >
          <Dropdown overlay={menu} placement='bottomRight'>
            <Menu.Item key='2' style={{ marginLeft: 'auto' }}>
              <Avatar size={40} icon={<UserOutlined />} src={user?.picture} />
            </Menu.Item>
          </Dropdown>
        </Menu> */}
      </div>
    </Layout.Header>
  );
};

export default Header;
