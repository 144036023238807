import { Spin, Typography } from 'antd';
const { Text } = Typography;

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Spin size="large" />
      <Text type="secondary" style={{ marginTop: 6 }}>
        Loading...
      </Text>
    </div>
  );
};

export default Loading;
