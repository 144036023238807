import { useState, useEffect } from 'react';
import Layout from './Layout';

import { Card, Steps, Typography, Row, Col, message } from 'antd';

import { Button } from './components';

import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

import { requestKlip } from './hooks/useAPI';
import { isMobile, isIOS } from 'react-device-detect';
import QRCode from 'qrcode';

import { verifyKlipResponse } from './hooks/useAPI';
const { Step } = Steps;
const { Text: TextBase } = Typography;

const Text = ({ style, ...rest }) => {
  return <TextBase {...rest} style={{ ...style, color: '#e5e5e5' }} />;
};

const { klaytn } = window;

function App() {
  const [account, setAccount] = useState();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [index, setIndex] = useState(0);
  const [type, setType] = useState();

  const connect = async () => {
    if (isMobile)
      return message.error('카이카스 지갑은 모바일에서 사용할 수 없습니다.');
    await klaytn.enable();
    setAccount(klaytn.selectedAddress);
    klaytn.on('accountsChanged', function (accounts) {
      console.log('Account change detected');
      setAccount(klaytn.selectedAddress);
    });
  };

  const renderContents = () => {
    if (result) {
      if (result === 'success') return <SuccessPage />;
      else if (result === 'fail') return <FailedPage />;
      else return <ErrorPage />;
    }

    if (type === 'klip') {
      return <KlipQR setResult={setResult} setAccount={setAccount} />;
    }

    if (account) {
      return <SignPage setResult={setResult} />;
    }

    return <ConnectPage connect={connect} setType={setType} />;
  };

  return (
    <Layout>
      {/* <Steps
        current={index}
        style={{ margin: 'auto', maxWidth: 720, marginBottom: 40 }}
      >
        <Step
          title={<Text style={{ fontWeight: '600' }}>Connect Wallet</Text>}
        />
        <Step
          title={<Text style={{ fontWeight: '600' }}>Verify Signature</Text>}
        />
        <Step title={<Text style={{ fontWeight: '600' }}>Earn Badges</Text>} />
      </Steps> */}
      {renderContents()}
    </Layout>
  );
}

function ConnectPage(props) {
  const { connect, setType } = props;
  return (
    <Row gutter={32} justify={'center'} align='middle'>
      <Col>
        <Card style={{ maxWidth: 300, marginTop: 20 }}>
          <img
            style={{ width: 96, height: 96 }}
            src='https://lh3.googleusercontent.com/Hj-ZkDTipLTJoKvT6Eh4LZHw-jRUfEyWkf3cyv6plIe4zYHRVhHsoKygayd38PPCLqw4YASg5YSFm833cM6AJQ46EQ=w128-h128-e365-rj-sc0x00ffffff'
          />
          <Button type='primary' onClick={connect} style={{ marginTop: 20 }}>
            Connect with Kaikas
          </Button>
        </Card>
      </Col>
      <Col>
        <Card style={{ maxWidth: 300, marginTop: 20 }}>
          <img
            style={{ width: 96, height: 96 }}
            src='https://www.gitbook.com/cdn-cgi/image/width=40,height=40,fit=contain,dpr=2,format=auto/https%3A%2F%2F2026560629-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252F-MJfGYCc3m68KWqX1870-887967055%252Ficon%252FDjyP2iPeJyxlLcSIUOyO%252F789cdc05017900001.png%3Falt%3Dmedia%26token%3D19e5146c-306b-474a-aea8-affe47693e41'
          />
          <Button
            type='primary'
            onClick={() => setType('klip')}
            style={{ marginTop: 20 }}
          >
            Connect with Klip
          </Button>
        </Card>
      </Col>
    </Row>
  );
}

function SignPage(props) {
  const { setResult } = props;

  const sign = async () => {
    const account = klaytn.selectedAddress;
    // @TODO: get message to sign from API
    const message = 'Message to sign';
    const signedMessage = await window.caver.klay.sign(message, account);
    // @TODO: send signed message and if it succeeds, go to success page
    const result = window.confirm(`Signed message: ${signedMessage}`);
    if (result) {
      setResult('success');
    } else {
      setResult('failed');
    }
  };
  return (
    <Card style={{ width: 360 }}>
      <h3>Great!</h3>
      <p>You're connected as {klaytn.selectedAddress}</p>
      <Button type='primary' onClick={sign} style={{ marginTop: 20 }}>
        Sign with Kaikas
      </Button>
    </Card>
  );
}

function KlipQR(props) {
  const { setResult, setAccount } = props;
  const [qr, setQR] = useState();
  const [reqKey, setReqKey] = useState();
  useEffect(() => {
    const getURL = async () => {
      const { request_key } = await requestKlip();
      setReqKey(request_key);
      if (isMobile) {
        window.daumtools.web2app({
          urlScheme: `kakaotalk://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=${request_key}`,
          intentURI: `intent://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=${request_key}#Intent;scheme=kakaotalk;package=com.kakao.talk;end`,
          storeURL: isIOS
            ? 'itms-apps://itunes.apple.com/app/id362057947'
            : 'market://details?id=com.kakao.talk',
          appName: '카카오톡',
          onUnsupportedEnvironment: function () {
            console.log('go to web page'); //example
          },
          willInvokeApp: function () {
            console.log('send click log'); //example
          },
        });
        // window.open(
        //   `kakaotalk://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=${request_key}`
        // );
      } else {
        const url =
          'https://klipwallet.com/?target=/a2a?request_key=' + request_key;
        QRCode.toDataURL(url, function (err, encoded) {
          setQR(encoded);
        });
      }
    };
    getURL();
  }, []);

  const _handleConfirm = async () => {
    const { status, result } = await verifyKlipResponse(reqKey);
    console.log(status);
    if (status === 'completed') {
      setResult('success');
      setAccount(result.klaytn_address);
    } else if (status === 'prepared') {
      message.info('Please finish Klip verification');
    } else {
      message.error('Something went wrong');
    }
  };

  return (
    <Card style={{ width: 360 }}>
      {qr && (
        <img style={{ width: 270, height: 270, marginTop: 20 }} src={qr} />
      )}
      <p>클립에서 인증을 완료하신 후, 아래 버튼을 눌러주세요.</p>
      <Button type='primary' onClick={_handleConfirm} style={{ marginTop: 20 }}>
        인증완료
      </Button>
    </Card>
  );
}

function SuccessPage(props) {
  return (
    <Result
      icon={<SmileOutlined />}
      title={<Text>Great, we have done all the operations!</Text>}
      subTitle={
        <>
          <Text>
            Please check the [channel-name] channel in server [server-name] for
            your role status.
          </Text>
          <br />
          <Text>The update may take up to 2 minutes to complete.</Text>
        </>
      }
      // extra={<Button type='primary'>Back home</Button>}
    />
  );
}

function FailedPage(props) {
  return <h1 style={{ color: '#e5e5e5' }}>Failed</h1>;
}

function ErrorPage(props) {
  return <h1 style={{ color: '#e5e5e5' }}>Error</h1>;
}

export default App;
